import React from 'react';
import PageWrapper from '../components/PageWrapper';
import HeaderButton from '../components/HeaderButton';

const Privacy = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: 'site-header--menu-right',
          headerButton: <HeaderButton />,
          footerStyle: 'style3',
        }}
      >
        <div className="pt-23 pt-md-25 pt-lg-30 pb-9 pb-md-16 pb-lg-21">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 col-md-11">
                <div className="text-center">
                  <h2 className="font-size-11">Privacy Policy</h2>
                </div>
              </div>
            </div>
            <div className="px-3">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-10 pr-lg-13 pr-xl-0">
                  <div className="pt-8">
                    <p>
                      This Privacy Policy is incorporated by reference into the
                      UStrive.com Terms of Service. The terms “UStrive.com,”
                      “we” and “us” include UStrive.com and our affiliates and
                      subsidiaries. The Privacy Policy explains how UStrive.com
                      may collect, use, and disclose information we obtain
                      through the “Service.” All capitalized terms that are not
                      defined in this Privacy Policy have the meaning given in
                      the Terms of Use.
                    </p>
                    <p>
                      “Personal Information” can be used to readily identify,
                      contact, or locate you. “Personal Information” means
                      information that alone or when in combination with other
                      information may be used to readily identify, contact, or
                      locate you, such as: name, address, email address, or
                      phone number. We do not consider Personal Information to
                      include information that has been anonymized so that it
                      does not allow a third party to easily identify a specific
                      individual.
                    </p>
                    <p>
                      <strong>THE SITE COLLECTS YOUR INFORMATION</strong>
                    </p>
                    <p>
                      We collect Personal Information when you: register to
                      serve as a mentor; register as a student; register to
                      start a UStrive.com chapter; make a donation; use the
                      Site; and communicate with us. We also collect
                      information, such as anonymous usage statistics, by using
                      cookies, server logs, and other similar technology as you
                      use the Site.
                    </p>
                    <p>
                      Register to Serve as a Mentor. We welcome new mentors. To
                      register to be a mentor, you need to provide Personal
                      Information, such as your name, email address, cellular
                      telephone number, and password. You may also be required
                      to submit additional Personal Information, including date
                      of birth, gender, mailing address, race, profile photo,
                      and biographical information. Register as a Student. We
                      welcome students to join our community. To register as a
                      student, you need to provide Personal Information, such as
                      your name, email address, and password. You may also be
                      required to submit additional Personal Information,
                      including date of birth, gender, zip code, race, parent’s
                      name, parent’s email address, parent’s phone number,
                      profile photo, and biographical information. You may also
                      provide optional information, such as your wireless
                      telephone number, GPA, and SAT scores ranges. Sign Up to
                      Start a UStrive.com Chapter on Your Campus. When you sign
                      up to start a UStrive.com chapter on your campus, you will
                      need to provide us with some Personal Information about
                      yourself, such as your name, email address, cellular
                      telephone number, date of birth. Background Checks. When
                      you register as a mentor or sign up to start a UStrive.com
                      chapter on campus, we may use a third-party service to
                      perform a background check on you with your consent. We do
                      not receive information from the background check other
                      than an indicator of whether you meet our requirements or
                      not. When You Make a Donation. When you make online
                      donations to UStrive.com, you need to provide financial
                      account information, such as your credit card number, to
                      our third-party service providers. We do not collect or
                      store such information, though we may receive summary
                      information that does not include credit card or bank
                      account numbers about transactions.
                    </p>
                    <p>
                      Communications through the Service. The Service may permit
                      users to communicate with each other through the Service,
                      by sending emails and text messages to other users. By
                      using the Service, you agree to receive email and text
                      messages from us. Customer Support. We may collect
                      Personal Information through your communications with our
                      customer-support team. Cookies, Automatic Data Collection,
                      and Related Technologies. The Site collects and stores
                      information that is generated automatically as you use it,
                      including your preferences and anonymous usage statistics.
                      When we associate such information with Personal
                      Information, we will treat the combination as Personal
                      Information. When you use the Site or receive emails from
                      us, we and our third-party partners, such as analytics
                      providers, use “cookies” and other similar technologies to
                      collect information about how the Site or email is
                      accessed. Our partners also may collect information about
                      your online activities over time and on other websites or
                      apps. When they provide such services, they are governed
                      by their own privacy policies. You may be able to change
                      browser settings to block and delete cookies when you
                      access the Site through a web browser. However, if you do
                      that, the Site may not work properly. By using the Site,
                      you are authorizing us to gather, parse, and retain data
                      related to the provision of the Site.
                    </p>
                    <p>
                      <strong>HOW UStrive.com USES YOUR INFORMATION</strong>
                    </p>
                    <p>
                      We use your information, including your Personal
                      Information, to: facilitate and improve our services; and
                      communicate with you.
                    </p>
                    <p>
                      We may use aggregate information for any purpose,
                      including for marketing purposes. Internal and
                      Service-Related Usage. We use information, including
                      Personal Information, for internal and service-related
                      purposes and may provide it to third parties to allow us
                      to facilitate the Site. We may use and retain any data we
                      collect to provide and improve any of our services.
                      Communications. We may send email to the email address you
                      provide to us, or text messages with your consent, to
                      verify your account and for informational and operational
                      purposes, such as account management, customer service,
                      system maintenance, and other Site-related information.
                    </p>
                    <p>
                      Marketing. We may use information, including Personal
                      Information, to provide online advertising on the Site and
                      to facilitate transmittal of information, such as by
                      email, we think may be useful or relevant to you.
                      Aggregate Data. We may anonymize or aggregate data
                      collected through the Site and use it for any purpose.
                    </p>
                    <p>
                      <strong>UStrive.com MAY DISCLOSE YOUR INFORMATION</strong>
                    </p>
                    <p>
                      We may share your information, including your Personal
                      Information: with our third-party vendors and service
                      providers; with other users; with academic institutions
                      and our sponsors; to comply with legal obligations; to
                      protect and defend our rights and property; and with your
                      permission.
                    </p>
                    <p>
                      We Use Vendors and Service Providers. We may share any
                      information we receive with vendors and service providers
                      retained in connection with the provision of the Site.
                      Displaying to Other Users. The information and content you
                      provide to the Site may be displayed on the Site. Other
                      users of the Site may be able to see some information
                      about you. For example, when a student registers and
                      searches for a mentor, we may display information about
                      mentors, such as their name, profile photo, and
                      biographical information, to students. We may display
                      information about students, including name, city, and
                      state, to mentors. We are not responsible for the privacy
                      practices of users who will view and use the posted
                      information. Marketing and Advertising. We share your
                      information, including Personal Information, with third
                      parties such as academic institutions and our sponsors so
                      that they can provide you with marketing information that
                      may be useful or relevant to you. We may allow access to
                      other data collected by the Site to enable the delivery of
                      online advertising on this website, or otherwise
                      facilitate transmittal of information we think may be
                      useful or relevant to you. Legal and Similar Disclosures.
                      We may access, preserve, and disclose collected
                      information, if we believe doing so is required or
                      appropriate to: comply with law enforcement requests and
                      legal process, such as a court order or subpoena; respond
                      to your requests; or protect your, our, or others’ rights,
                      property, or safety. Merger, Sale, or Other Asset
                      Transfers. If we are involved in a merger, acquisition,
                      financing due diligence, reorganization, bankruptcy,
                      receivership, sale of UStrive.com assets, or transition of
                      service to another provider, your information may be sold
                      or transferred as part of such a transaction as permitted
                      by law and/or contract. We cannot control how such
                      entities may use or disclose such information. With Your
                      Permission. We may also disclose your Personal Information
                      with your permission.
                    </p>
                    <p>
                      <strong>INFORMATION SECURITY</strong>
                    </p>
                    <p>
                      We take steps to ensure that your information is treated
                      securely and in accordance with this Privacy Policy.
                      Unfortunately, the Internet cannot be guaranteed to be
                      100% secure, and we cannot ensure or warrant the security
                      of any information you provide to us. We do not accept
                      liability for unintentional disclosure. By using the Site
                      or providing Personal Information to us, you agree that we
                      may communicate with you electronically regarding
                      security, privacy, and administrative issues relating to
                      your use of the Site. If we learn of a security system’s
                      breach, we may attempt to notify you electronically by
                      posting a notice on the Site or sending an email to you.
                      You may have a legal right to receive this notice in
                      writing. To receive free written notice of a security
                      breach (or to withdraw your consent from receiving
                      electronic notice), please notify us at
                      support@UStrive.com.
                    </p>
                    <p>
                      <strong>CHILDREN’S PRIVACY</strong>
                    </p>
                    <p>
                      We do not knowingly collect information from children
                      under 13. We will take steps to delete it if we learn we
                      have collected it. We do not knowingly collect, maintain,
                      or use personal information from children under 13 years
                      of age, and no part of the Site is directed to children
                      under the age of 13. If you learn that your child has
                      provided us with personal information without your
                      consent, you may alert us at support@UStrive.com. If we
                      learn that we have collected any personal information from
                      children under 13, we will promptly take steps to delete
                      such information and terminate the child’s account.
                    </p>
                    <p>
                      <strong>INTERNATIONAL USERS</strong>
                    </p>
                    <p>
                      By using the Site, you will transfer data to the United
                      States. If you are visiting from the European Union or
                      other regions with laws governing data collection and use,
                      please note that you are agreeing to the transfer of your
                      information to the United States and processing globally.
                      By providing your information you consent to any transfer
                      and processing in accordance with this Privacy Policy.
                    </p>
                    <p>
                      <strong>YOUR CHOICES</strong>
                    </p>
                    <p>
                      Send an email to support@UStrive.com if you would like to
                      update or correct any information that you have provided
                      to us through your use of the Site or otherwise, or if you
                      have suggestions for improving this Privacy Policy.
                    </p>
                    <p>
                      If you are under the age of 18 and a California resident,
                      you may request that content or information you posted to
                      the Site no longer be publicly accessible from the Site by
                      sending an email to support@UStrive.com. After completing
                      the request, we may continue to hold your content and
                      information and such content and information may remain
                      publicly available if your association with the content
                      and information is removed or another user posted, copied,
                      or re-posted the content and information.
                    </p>
                    <p>
                      If you would like to opt out of having your personal
                      information shared with third parties for their direct
                      marketing purposes (as defined by Cal. Civ. Code Section
                      1798.83), please send an email to support@UStrive.com.
                    </p>
                    <p>
                      <strong>
                        CHANGES TO OUR PRIVACY POLICY AND PRACTICES
                      </strong>
                    </p>
                    <p>
                      We may revise this Privacy Policy, so review it
                      periodically. Posting of Revised Privacy Policy. We will
                      post any adjustments to the Privacy Policy on this web
                      page, and the revised version will be effective when it is
                      posted. If you are concerned about how your information is
                      used, bookmark this page and read this Privacy Policy
                      periodically. New Uses of Personal Information. From time
                      to time, we may desire to use Personal Information for
                      uses not previously disclosed in our Privacy Policy. If
                      our practices change regarding previously collected
                      Personal Information in a way that would be materially
                      less restrictive than stated in the version of this
                      Privacy Policy in effect at the time we collected the
                      information, we will make reasonable efforts to provide
                      notice and obtain consent to any such uses as may be
                      required by law.
                    </p>
                    <p>
                      <strong>Contact Information</strong>
                    </p>
                    <p>
                      UStrive, Inc.
                      <br />
                      360 Nueces St, Austin, TX 78701
                    </p>
                    <p>support@UStrive.com</p>
                    <p>Effective Date: 1/1/2018</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Privacy;
